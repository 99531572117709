<template>
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="form-email">
    <h5 class="text-center text-primary font-22">
      <span v-if="errorEmail == false">
        <u>ขั้นตอนที่ 1</u> กรอกข้อมูลอีเมลและรหัสผ่าน
      </span>
      <el-alert
        title="อีเมลถูกใช้งานแล้ว"
        type="error"
        show-icon
        v-if="errorEmail"
        @close="closeErrorEmail"
      >
      </el-alert>
    </h5>

    <!-- <p class="mg-b-0 text-center">
      คุณมีบัญชีอยู่แล้ว ?
      <router-link
        :to="
          typeof this.$route.query.code != 'undefined'
            ? '/sign-in?code=' + this.$route.query.code
            : '/sign-in'
        "
        ><u>กรุณากดที่นี่เพื่อเข้าสู่ระบบ</u></router-link
      >
    </p> -->
    <el-form-item label="อีเมล" prop="email">
      <el-input
        type="email"
        id="email"
        v-model="ruleForm.email"
        :disabled="edit"
        aria-label="Email"
      ></el-input>
    </el-form-item>
    <el-form-item label="รหัสผ่าน" prop="password" v-if="!edit">
      <el-input
        id="password"
        type="password"
        show-password
        v-model="ruleForm.password"
        @input="validPass"
        minlength="8"
        maxlength="30"
        placeholder="กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัวอักษร"
      ></el-input>
      <password
        v-model="ruleForm.password"
        :strength-meter-only="true"
        @score="showScore"
        @feedback="showFeedback"
      />
    </el-form-item>
    <el-form-item label="ยืนยันรหัสผ่าน" prop="repassword" v-if="!edit">
      <el-input
        type="password"
        id="repassword"
        show-password
        v-model="ruleForm.repassword"
        minlength="8"
        maxlength="30"
        aria-label="Password"
        placeholder="กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัวอักษร"
      ></el-input>
    </el-form-item>
    <div class="font-20 pd-t-5">
      <el-radio 
        v-model="ruleForm.consent"
        id="consent"
        :label="1"
        class="mg-r-6"
        aria-label="Consent"
      >
      ข้าพเจ้ายินยอม
      <!-- ฉันได้อ่าน และยอมรับ -->
      <!-- <a :href="termLink" target="_blank">เงื่อนไขการใช้บริการ</a> และ -->
      
      </el-radio>
    </div>
    <div class="font-20 pd-t-5">
      <el-radio 
        v-model="ruleForm.consent"
        :label="0"
        id="consent"
        class="mg-r-6"
        aria-label="Consent"
      >
      ข้าพเจ้าไม่ยินยอม
      <!-- ฉันได้อ่าน และยอมรับ -->
      <!-- <a :href="termLink" target="_blank">เงื่อนไขการใช้บริการ</a> และ -->
      
      </el-radio>
      <span class="color-primary">
          ให้ ธสน. ใช้และ/หรือเปิดเผย ชื่อ-นามสกุล และข้อมูลการติดต่อของข้าพเจ้าให้กับหน่วยงานพันธมิตรของ
          ธสน. ด้านการสนับสนุนทางการเงิน เพื่อการติดต่อประชาสัมพันธ์ และ/หรือ
          เสนอกิจกรรมทางการตลาด ทั้งนี้ ท่านสามารถศึกษาหลักการ ความเป็นส่วนตัวของ ธสน. ได้ที่ <a href="https://www.exim.go.th/th/privacypolicy.aspx" target="_blank"><u>https://www.exim.go.th/th/privacypolicy.aspx</u></a>  หรือ สแกน QR Code
      </span>
    </div>
    <div class="text-center">
      <img src="@/assets/qr-privacy.png" class="mw-100" alt="">
    </div>
    <el-form-item class="text-center mg-t-1">
      <el-button type="primary" id="next" class="wid220" @click="nextStep()"
        >ต่อไป</el-button
      >
    </el-form-item>
    <el-dialog width="90%" :visible.sync="privacy">
      <h3 class="text-center word-break">
        กรุณาอ่านและยอมรับ
        <!-- <a :href="termLink" target="_blank">เงื่อนไขการใช้บริการ</a> และ -->
        <a target="_blank" href="#">นโยบายการคุ้มครองข้อมูลส่วนบุคคลของ EXIM</a>
      </h3>
    </el-dialog>
  </el-form>
</template>
<script>
import { HTTP } from "@/service/axios";
import Password from "vue-password-strength-meter";
export default {
  components: {
    Password
  },
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    ruleForm: {
      type: Object,
      default: {
        email: "",
        password: "",
        repassword: ""
      }
    }
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("กรุณากรอกรหัสผ่าน"));
      } else {
        if (this.ruleForm.repassword !== "") {
          this.$refs.ruleForm.validateField("confirmPass");
        }
        let valid = new RegExp( /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[""!@#$%^&*()_+|~\-=`{}\[\]:<>?,./\'\;\\])[A-Za-z\d!@#$%^&*()_+|~\-=\`{}[\]:\"";'<>?,.\/\\]{8,}$/ );
        if (!valid.test(value))
        {
          callback(new Error( "รหัสผ่านต้องมีความยาว 8 ตัวอักษรขึ้นไป โดยประกอบด้วย ตัวอักษรพิมพ์เล็ก(a-z) พิมพ์ใหญ่(A-Z) ตัวเลข และ อักขระพิเศษ" ));
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("กรุณากรอกรหัสผ่านอีกครั้ง"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("รหัสผ่านไม่ตรงกัน"));
      } else {
        let valid = new RegExp( /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[""!@#$%^&*()_+|~\-=`{}\[\]:<>?,./\'\;\\])[A-Za-z\d!@#$%^&*()_+|~\-=\`{}[\]:\"";'<>?,.\/\\]{8,}$/ );
        if(!valid.test(value))
        {
          callback(new Error("ยืนยันรหัสผ่านต้องมีความยาว 8 ตัวอักษรขึ้นไป โดยประกอบด้วย ตัวอักษรพิมพ์เล็ก(a-z) พิมพ์ใหญ่(A-Z) ตัวเลข และ อักขระพิเศษ"));
        }
        callback();
      }
    };
    return {
      urlSignin:
        typeof this.$route.query.code != "undefined"
          ? "/sign-in?code=" + this.$route.query.code
          : "/sign-in",
      errorEmail: false,
      showFeedbackMsg: "",
      privacy: false,
      privacyLink: "https://www.exim.go.th/th/privacypolicy.aspx",
      termLink:
        "https://www.exim.go.th/th/EXIM-SLA/%E0%B8%82%E0%B8%AD%E0%B8%95%E0%B8%81%E0%B8%A5%E0%B8%87%E0%B8%A3%E0%B8%B0%E0%B8%94%E0%B8%9A%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%83%E0%B8%AB%E0%B8%9A%E0%B8%A3%E0%B8%81%E0%B8%B2%E0%B8%A3-(1).aspx",
      rules: {
        email: [
          {
            required: true,
            message: "กรุณากรอกอีเมล",
            trigger: "blur",
            type: "email"
          }
        ],
        password: [
          { required: true, message: "กรุณากรอกรหัสผ่าน", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
          {
            min: 8,
            message: "รหัสผ่านต้องมีความยาวไม่น้อยกว่า 8 ตัวอักษร",
            trigger: "blur"
          }
        ],
        repassword: [
          { required: true, message: "กรุณากรอกรหัสผ่าน", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
          {
            min: 8,
            message: "รหัสผ่านต้องมีความยาวไม่น้อยกว่า 8 ตัวอักษร",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    }
  },
  mounted() {},
  methods: {
    validPass() {
      let arr = ["..%u2216", "..%c0%af", "%2e%2e%2f", "..%255c", "../", "..\\"];
      let reg = new RegExp(
        /..%u2216|..%c0%af|..%c1%9c|%2e%2e%2f|%2e%2e\/|..%2f|%2e%2e\\|..%5c|%252e%252e%255c|..%255c|\.\.\/|\.\/|\.\.\\|\.\\|\.\.|%00|%0|%2|%5|%%|%c|%d|%e|%f|%p|%u|%x|%s|%n|%\d{1,2}!s|%\d{1,2}!n\w+/g,
        "gi"
      );

      let index = reg.test( this.ruleForm.password );
      let matches = this.ruleForm.password.match(reg);
      if (index) {
        if (matches.length != null) {
          matches.map(data => {
            this.ruleForm.password = this.ruleForm.password.replace(data, "");
          });
        }
        this.$message.error("ห้ามใช้อักขระพิเศษ");
      }
    },
    showScore(score) {},
    showFeedback({ suggestions, warning }) {
      this.showFeedbackMsg = suggestions;
    },
    nextStep() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          // if (this.ruleForm.privacyPolicy == 1) {
          HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
          HTTP.post(`check/email`, { email: this.ruleForm.email }).then(res => {
            if (res.data.success) {
              this.$emit("nextStep");
            } else {
              this.errorEmail = true;
              var timerError = setTimeout(() => {
                this.errorEmail = false;
              }, 5000);
              if (this.errorEmail == false) {
                clearInterval(timerError);
              }
              window.scroll({ top: 0, left: 0, behavior: "smooth" });
            }
          });
          // } else {
          //   this.privacy = true;
          // }
        } else {
        }
      });
    },
    closeErrorEmail() {
      this.errorEmail = false;
      clearInterval(timerError);
    }
  }
};
</script>
<style>
.Password__strength-meter{
    margin: 10px auto;
}
</style>